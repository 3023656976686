// display css properties
.d-block {
    display: block;
}

.d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.d-flex-j {
    display: flex;
    justify-content: center;
}

.d-flex-a {
    display: flex;
    align-items: center;
}

.d-flex-col {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.d-grid {
    display: grid;
}

.rt {
    right: 0;
}

.lt {
    left: 0;
}

.btm {
    bottom: 0;
}

.top {
    top: 0;
}

.rt-10 {
    right: 10px;
}

.lt-10 {
    left: 10px;
}

.btm-10 {
    bottom: 10px;
}

.top-10 {
    top: 10px;
}