/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */


/* Core CSS required for Ionic components to work properly */

@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */

@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */

@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "./theme/variables.scss";
@import "./theme/custom-global-style.scss";
@import "./theme/color.scss";
@import "./theme/display.scss";
@import "./theme/font.scss";
@import "./theme/heading.scss";
@import "./theme/margin.scss";
@import "./theme/padding.scss";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'assets/material/material.css';
@import '~intro.js/introjs.css';
@import "assets/animate.min.css";

/* Add application styles & imports to this file! */

* {
    transition: ease 0.5s;
}

.card-elements {
    padding: 10px 16px 16px 16px;
}

.StripeElement {
    background-color: white;
    // height: 40px;
    padding: 5px 0;
    border-radius: 0px;
    border-bottom: 2px solid var(--ion-color-medium);
    // box-shadow: 0 1px 3px 0 #b8cbe5;
    // -webkit-transition: box-shadow 150ms ease;
    // transition: box-shadow 150ms ease;
}

// .StripeElement--focus {
//     // box-shadow: 0 1px 3px 0 #6c737b;
// }
.StripeElement--invalid {
    border-color: #fa755a;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.two-element {
    padding-top: 5px;
    .expiry-div {
        width: 48%;
        display: inline-grid;
        margin-right: 2%;
    }
    .cvv-div {
        width: 48%;
        display: inline-grid;
        margin-left: 2%;
    }
}

.palyment-lable {
    font-size: 12px;
}

.payment-error {
    font-size: 12px;
}

ion-button {
    text-transform: capitalize;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.currencydiv {
    .currency_symbol {
        position: absolute;
        bottom: 0;
        padding: 12px 10px;
    }
    ion-input {
        padding-left: 25px !important;
    }
}

app-welcome {
    margin-top: -3rem !important;
}

.display-none{
    display: none !important;
}
.ModalViewLogin{
    app-welcome {
        margin-top: 0 !important;
    }
    
.top-space {
    margin-top: 0 !important;
    padding: 0 !important;
}

}

@media screen and (min-width: 970px) {
    .show-tab-bar {
        display: none;
    }
    app-welcome {
        margin-top: 0rem !important;
    }

  
}

.timepicker {
    $someColor : var(--ion-color-primary);
    .timepicker__header {
      background-color: $someColor !important;
    }
  
    .clock-face__clock-hand {
      background-color: $someColor !important;
  
      &::after {
        background-color: $someColor !important;
      }
  
      &::before {
        border-color: $someColor !important;
      }
    }
  
    .clock-face__number {
      > span.active {
        background-color: $someColor !important;
      }
    }
  
    button:not(.timepicker-dial__item) {
      color: $someColor !important;
    }
  }

  .media{
    swiper-slide{
        width: 518px;
        display: flex;
        position: relative;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        height: 100vh;
        font-size: 18px;
        text-align: center;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    
    }
  }

  #web-header-top{
    .profile_initials{
        height: 35px;
    }
}

//for welcome page
.horizontal_slider{
   
.program-wrapper {
    width: 220px;
    flex-grow: 1;
    height: 175px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.program-pic {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.program-details {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: white;
    z-index: 2;
}

.program-details-top,
.program-details-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.program-name {
    font-weight: 500;
    font-size: 13px;
}

.program-price {
    font-weight: 500;
    font-size: 16px;
    text-wrap: nowrap;
}

.program-author {
    font-weight: 400;
    font-size: 10px;
}

.program-capacity {
    font-weight: 500;
    font-size: 10px;
}

.backdrop {
    background: rgba(45, 54, 91, 0.4);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    filter: blur(5px);
    z-index: -1;
    backdrop-filter: blur(7.5px);
    border-radius: 10px;
}

hr {
    margin: 0.5rem 0;
    background: lightgray;
}

.program-list {
    padding: 0 1rem 1rem 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-content: center;
    justify-content: center;
}

    
}


.option1{
 
    overflow-x: auto;
    .program-list {
        padding: 0 1rem 1rem 1rem;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem !important;
        align-content: center;
        justify-content: center;
    }
    .program-pic {
        object-fit: cover;
        width: 100%;
        height: 150px !important;
        border-radius: 10px;
    }
    
    .program-name {
        font-size: 16px !important;
        font-weight: bold !important;
    }
    .program-wrapper{
        height: auto  !important;
        width: 220px;
        max-width: 300px;
        flex-grow: 1;
        height: 175px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        background: rgba(255, 255, 255, 0.2);
        box-shadow: 0 8px 10px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(10.5px);
        cursor: pointer;
    }
    .scheduled {
        font-weight: 600 !important;
        font-size: 14px !important;
    }
    .program-author {
        font-weight: 400 !important;
        font-size: 13px !important;
    }
    .program-price {
        font-weight: 500 !important;
        font-size: 12px !important;
        text-wrap: nowrap;
    }
    .blackimg{
        filter: brightness(0%);
    }
    .left-chip{
        position: absolute;
        top: 2px;
        left: 2px;
        color: white;
        font-size: 13px;
        background: #732da9;
        border-radius: 14px;
        padding: 4px 8px 4px 8px;
        margin: 4px;
    }
    .right-chip{
        position: absolute;
        top: 2px;
        right: 2px;
        color: white;
        font-size: 13px;
        background: #732da9;
        border-radius: 14px;
        padding: 4px 8px 4px 8px;
        margin: 4px;
    }
    .program-type{
        position: absolute;
        top: 115px;
        left: 2px;
        width: 95%;
        color: white;
        font-size: 13px;
        background: #732da9a3;
        border-radius: 14px;
        padding: 4px 8px 4px 8px;
        margin: 4px;
    }
    .program-capacity{
        font-size: 13px !important;
    }

}

.page-sub-header{
    margin: 30px 0;
}
app-home-general-view{
    
.not-public{
    display: none !important;
}
.top-spacing-web{
    justify-content: space-around !important    ;
}
}
.sticky-info{
    position: sticky;
    background-color: #ffffff;
    z-index: 100;
    top: 0;
    padding: 10px;
}


@media screen and (max-width: 425px) {
    app-program-details{
        app-web-header-bar{
            display: none;
        }
    }


}

@media screen and (min-width: 1024px) {
    .logo-elemet {
        display: none;
    }

    .logo-full {
        display: block;
    }
    .sticky-info{
        display: none;
    }
    .options-left{
        display: none;
    }
    .mobile_element{
        display: none !important;
    }
 
}

.top-padding{
    padding-top: 50px;
}


.pdf-details{
    position: absolute;
    width: 100%;
    background: #0000006b;
    z-index: 1;
    padding: 4px 25px;
    color: white;
    font-size: 13px;
    top: 0;
    left: 0;
    right: 0;
    ion-icon{
        color: #ffffff !important;
        position: initial !important;
        background-color : transparent !important;
        border: 0 !important;
        transform: none !important;
        font-size: 20px !important;
         padding: 0 !important;
    }
    .page-counts{
        font-size: 13px;
        color: #ffffff;
        vertical-align: super;
        padding-left: 5px;
    }
    img{
        width: 25px;
    }
}
