// margin
.m-0 {
    margin: 0;
}

.m-8-hr {
    margin: 0 8px;
}

.m-8-vt {
    margin: 8px 0;
}

.m-16 {
    margin: 16px;
}

.m-16-hr {
    margin: 0 16px;
}

.m-16-vt {
    margin: 16px 0;
}

.m-24-hr {
    margin: 0 24px;
}

.m-24-vt {
    margin: 24px 0;
}

.m-24 {
    margin: 24px;
}

.m-32 {
    margin: 32px;
}

.m-0-5-t {
    margin-top: 0.5rem;
}
.m-5-t{
    margin-top: 5rem;
}

.m-0-5-r {
    margin-right: 0.5rem;
}

.m-0-5-b {
    margin-bottom: 0.5rem;
}

.m-0-5-l {
    margin-left: 0.5rem;
}