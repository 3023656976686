* {
    font-family: 'Nunito', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

app-search-results {
    position: absolute;
    z-index: 100;
    background: white;
    filter: drop-shadow(5px 5px 10px #000000);
    border-radius: 1rem;
    top: 7rem;
    left: 1rem;
}

ion-modal::part(backdrop) {
    background: #1f1b2b;
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.50) !important;
    //   -webkit-mask-image: linear-gradient(to top, black 10%, transparent 200%) !important;
    //   mask-image: linear-gradient(to top, black 10%, transparent 80%) !important;
}

.present-confirm-go-back-alert,
.my-enrollment-sort-options-popover,
.my-review-options-popover,
.registration-form-modal,
.forgot-page-modal {
    backdrop-filter: blur(5px);
}

// ion-backdrop {
//     background: black;
//     --backdrop-opacity: var(--ion-backdrop-opacity, 0.85) !important;
//     -webkit-mask-image: linear-gradient(to top, black 10%, transparent 80%) !important;
//     mask-image: linear-gradient(to bottom, black 10%, transparent 80%);
// }
::-webkit-scrollbar {
    display: none;
}

// custom
.underline {
    border-bottom: 2px solid var(--ion-color-medium);
}

.valid {
    border-bottom: 2px solid var(--ion-color-success);
}

.invalid {
    border-bottom: 2px solid var(--ion-color-danger);
}

.bringUp {
    z-index: 1000;
}

// ion-input[type="email"] {
//     text-transform: lowercase;
// }
.hide {
    display: none;
}

.header-alignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

p.header-alignment>span {
    font-weight: 700 !important;
    font-size: 32px;
    text-align: center;
}

p.sub-header>span {
    font-weight: 700 !important;
    text-align: start !important;
}

.text-center {
    text-align: center;
}

.header-icon {
    padding: 1rem;
    font-size: 32px;
}

.sub-header-icon {
    padding: 1rem;
}

.parent {
    position: relative;
}

.child {
    position: absolute;
}

.sticky {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    padding: 2rem 0rem 1rem 0rem;
    background-color: white;
}

.no-pad {
    padding: 0 0 0 0 !important;
}

.opacity-0 {
    opacity: 0;
}

.z-60 {
    zoom: 0.6;
}

.z-80 {
    zoom: 0.8;
}

.size-30 {
    height: 30px !important;
    width: 30px !important;
}

.size-40 {
    height: 40px !important;
    width: 40px !important;
}

.size-50 {
    height: 50px !important;
    width: 50px !important;
}

.size-105 {
    height: 105px !important;
    width: 105px !important;
}

.main-content-bottom-margin {
    margin-bottom: 50px;
}

ion-button {
    --background-activated: var(--ion-color-custom-secondary);
    --background-focused: var(--ion-color-custom-secondary);
    --background-hover: var(--ion-color-custom-secondary);
    --color-hover: var(--ion-color-light);
}

.time-slot-entry-popover {
    --width: 80%
}

.my-review-options-popover {
    --width: 100px
}

.emptypadding-100 {
    padding: 50px;
}

.list-review {
    ion-list {
        border-radius: 0;
    }

    ion-item {
        --background: rgb(115 44 168 / 6%);
    }

    ion-label {
        margin-left: 10px;
        color: #000000;
        padding: 10px 0px;

        .author-name {
            font-weight: 700;
            line-height: 1.2;
            letter-spacing: 0;
            font-size: 18px;
            color: #000000;
            display: flex;
            align-items: center;
            padding-bottom: 5px;

            ion-avatar {
                width: 35px;
                height: 35px;
            }

            span {
                margin-left: 10px;
            }
        }

        .review-time {
            color: #000000;
            margin-left: 10px;
            font-size: 14px;
        }

        .review-text {
            color: #000000;
            font-size: 16px;
            white-space: initial;
        }
    }

    .edit-review {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
}

.modal-close {
    font-size: 25px;
    float: right;
    font-weight: 400;
    min-height: auto;
    min-width: auto;
    padding-inline: 0;
    color: var(--ion-color-primary);
    --background: #ffffff;
    --background-activated: #ffffff;
    --background-focused: #ffffff;
    --background-hover: var(--ion-color-custom-accent-30);
    --color-hover: #000000;
    --border-radius: 30px;
    --padding-top: 5px;
    --padding-bottom: 5px;
    --padding-start: 5px;
    --padding-end: 5px;
    margin: 5px;
}

.Enrolledbtn {
    padding: 10px;
    color: #ffffff;
    background: var(--ion-color-custom-accent);
}

ion-icon {
    cursor: pointer;
}

app-show-all-reviews,
app-show-all-announcements,
app-payment-form {
    margin: 3rem;
}

app-payment-summary {
    height: 70%;
}

.initials {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    font-size: 20px;
    width: 35px;
    text-align: center;
    height: 35px;
    background: rgb(115 45 169 / 21%);
    color: #8a5f5f;

    span {
        margin-left: 0 !important;
        vertical-align: sub;
        text-align: center;
    }
}

.displyNOne {
    display: none !important;
}

.errormsg {
    padding-left: 16px;
}

.change-picture-btn {
    position: absolute;
    right: 0px;
    bottom: 0px;
    --border-radius: 30px;
}

.input-upload-profile-picture {
    position: absolute;
    width: 0px;
    height: 0px;
    opacity: 0;
}

.loadinvElement {
    --spinner-color: var(--ion-color-custom-accent);
}

.toastElement {
    --background: var(--ion-color-success);
}

@media screen and (min-width: 1024px) {
    app-search-results {
        position: absolute;
        z-index: 100;
        background: white;
        filter: drop-shadow(5px 5px 10px #000000);
        border-radius: 1rem;
        top: 8rem;
        left: 1rem;
    }
}