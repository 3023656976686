// padding
.p-0 {
    padding: 0;
}

.p-8-hr {
    padding: 0 8px;
}

.p-16-hr {
    padding: 0 16px;
}

.p-24-hr {
    padding: 0 24px;
}

.p-8-vt {
    padding: 8px 0;
}

.p-16-vt {
    padding: 16px 0;
}

.p-24-vt {
    padding: 24px 0;
}

.p-8 {
    padding: 8px;
}

.p-16 {
    padding: 16px;
}

.p-24 {
    padding: 24px;
}

.p-r-16 {
    padding-right: 16px;
}

.p-l-16 {
    padding-left: 16px;
}

.p-b-4 {
    padding-bottom: 4px;
}