// headings
.h1 {
    font-size: 52px;
}

.h2 {
    font-size: 39px;
}

.h3 {
    font-size: 29px;
}

.h4 {
    font-size: 22px;
}

.h5 {
    font-size: 16px;
}

.h6 {
    font-size: 14px;
}

.h7 {
    font-size: 12px;
}

.h8 {
    font-size: 9px;
}