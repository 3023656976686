// color
.red {
    color: var(--ion-color-danger) !important;
}

.green {
    color: var(--ion-color-success) !important;
}

.grey {
    color: var(--ion-color-medium) !important;
}

.white {
    color: var(--ion-color-light) !important;
}

.purple {
    color: var(--ion-color-custom-accent) !important;
}

.black {
    color: black;
}